<template>
  <el-select :value="value" @change="changeValue" :size="size" placeholder="">
    <el-option key="0" label="GMT +9:00 (Tokyo)" value="Asia/Tokyo" />
    <el-option key="1" label="GMT +8:00 (Shanghai)" value="Asia/Shanghai" />
    <el-option key="2" label="GMT +8:00 (Taipei)" value="Asia/Taipei" />
    <el-option key="3" label="GMT -4:00 (New York)" value="America/New_York" />
    <el-option key="4" label="GMT -5:00 (Chicago)" value="America/Chicago" />
    <el-option key="5" label="GMT -6:00 (Denver)" value="America/Denver" />
    <el-option
      key="6"
      label="GMT -7:00 (Los Angeles)"
      value="America/Los_Angeles"
    />
    <el-option key="7" label="GMT -8:00 (Anchorage)" value="America/Anchorage" />
    <el-option key="8" label="GMT -10:00 (Hawaii)" value="Hawaii" />
  </el-select>
</template>

<script>
import profileApi from "@/apis/profile";

export default {
  name: "Timezone",
  props: {
    value: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: ""
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
