<template>
  <el-select
    :value="value"
    @change="changeValue"
    filterable
    :size="size"
    placeholder="Select"
    style="width: 100%"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import courseApi from "@/apis/course";

export default {
  name: "SessionClass",
  props: {
    value: {
      type: Number,
      default: null
    },
    size: {
      type: String,
      default: "default"
    }
  },
  async created() {
    const {
      session_classes
    } = await courseApi.fetchSessionClassesForSelector();
    this.options = session_classes;
  },
  data() {
    return {
      options: []
    };
  },
  methods: {
    changeValue(value) {
      const sessionClass = this.options.find(option => option.id === value);
      this.$emit("change", sessionClass);
    }
  }
};
</script>
