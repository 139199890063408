<template>
  <el-select
    :value="value"
    @change="changeValue"
    :placeholder="$t('studentDetail.gradeLevel')"
    style="width:100%"
    @focus="colorChangeGrade = true"
    @blur="colorChangeGrade = false"
  >
    <div
      slot="prefix"
      class="input-icon icon-school"
      :style="
        colorChangeGrade
          ? 'background-color: #42a16a;'
          : 'background-color: #757575;'
      "
    ></div>
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="`${$t(item.label, { year: item.param })}`"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
import moment from "moment";
import gradeLevelMixin from "@/mixins/gradeLevel";

export default {
  name: "GradeLevel",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  mixins: [gradeLevelMixin],
  async created() {
    this.options = this.createStudentGraduateSelection();
  },
  data() {
    return {
      options: [],
      colorChangeGrade: false
    };
  },
  methods: {
    changeValue(gradeLevel) {
      this.$emit("change", gradeLevel);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-icon {
  width: 22px;
  height: 22px;
  margin-top: 7px;
  background-size: cover;
}
.icon-school {
  mask: url("../../assets/svg/school.svg") no-repeat;
}
</style>
