<template>
  <el-select
    :disabled="disabled"
    :size="size"
    :value="value"
    @change="changeValue"
    filterable
    placeholder="Select"
    :style="customStyle"
  >
    <el-option
      v-for="item in filterEnableApps(apps)"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";

export default {
  name: "ThirdApp",
  props: {
    value: {
      type: String,
      default: null
    },
    change: {
      type: Function,
      default: () => {}
    },
    size: {
      type: String,
      default: "medium"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: Object,
      default: () => ({ width: "100%" })
    },
    enableApps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      apps: []
    };
  },
  created() {
    this.apps = Object.keys(thirdAppTypeDict).map((thirdAppType) => ({
      id: thirdAppType,
      name: thirdAppTypeDict[thirdAppType]
    }));
  },
  methods: {
    changeValue(value) {
      const app = this.apps.find(option => option.id === value);
      this.$emit("change", app);
    },
    filterEnableApps() {
      return this.apps.filter((app) => (
        this.enableApps.find(({ service }) => (
          service === app.id
        ))
      ));
    }
  }
};
</script>
