<template>
  <el-select
    :value="value"
    @change="changeValue"
    filterable
    :size="size"
    placeholder="Select"
    style="width: 100%"
  >
    <el-option
      v-for="item in options"
      :key="item.user_id"
      :label="`${item.name} (${item.email})`"
      :value="item.user_id"
    >
    </el-option>
  </el-select>
</template>

<script>
import arrangeApi from "@/apis/user";

export default {
  name: "Student",
  props: {
    students: {
      type: Array,
      default: null
    },
    value: {
      type: Number,
      default: null
    },
    size: {
      type: String,
      default: "default"
    }
  },
  watch: {
    students: {
      immediate: true,
      handler(students) {
        this.options = students;
        this.studentsOptions = JSON.parse(JSON.stringify(students));
      }
    }
  },
  async created() {
    if (this.students === null) {
      const students = await arrangeApi.searchStudents();
      this.options = students;
      this.studentsOptions = JSON.parse(JSON.stringify(students));
    }
  },
  data() {
    return {
      options: [],
      studentsOptions: []
    };
  },
  methods: {
    changeValue(value) {
      const student = this.options.find(option => option.user_id === value);
      this.$emit("change", student);
    },
    filterMethod(value) {
      this.options =  JSON.parse(JSON.stringify(this.studentsOptions));
      console.log(value);
      console.log(this.options);
      let arr = [];
      if(this.isChn(value)){
        this.options.forEach((option)=>{
          if(value.length === 1){
            let str =  `${option.name} (${option.email})`;
            if(str.indexOf(value) > -1){
              arr.push(option);
            };
          }
        });
      } else{
        this.options.forEach((option)=>{
          let str =  `${option.name} (${option.email})`;
          if(str.indexOf(value) > -1){
            arr.push(option);
          };
        });
      }
      this.options = arr;
      // return true;
    },
    isChn(str) {
      var reg = /^[\u4E00-\u9FA5]+$/;
      if (!reg.test(str)) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
