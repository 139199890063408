<template>
  <el-select
    :disabled="disabled"
    :size="size"
    :value="value"
    @change="changeValue"
    filterable
    placeholder="Select"
    :style="customStyle"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "Currency",
  props: {
    value: {
      type: String,
      default: null
    },
    change: {
      type: Function,
      default: () => {}
    },
    size: {
      type: String,
      default: "medium"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: Object,
      default: () => ({ width: "100%" })
    }
  },
  data() {
    return {
      options: [
        { id: "TWD", name: "TWD" },
        { id: "USD", name: "USD" },
        { id: "CNY", name: "CNY" }
      ]
    };
  },
  methods: {
    changeValue(value) {
      const currency = this.options.find(option => option.id === value);
      this.$emit("change", currency);
    }
  }
};
</script>
