import moment from "moment";

export default {
  methods: {
    createStudentGraduateSelection() {
      const today = moment().format();
      const year = today.substring(0, 5);
      const month = today.substring(5, 7);
      let options = [];
      if (!month) return options;
      const offset = parseInt(month) < 7 ? 0 : 1;

      let curYear = parseInt(year);
      // 已畢業
      for (let i = 10; i > 0; i--) {
        let item = {};
        item.label = "gradeLevel.graduated";
        item.param = curYear - i + offset;
        item.value = (curYear - i - 4 + offset).toString();
        options.push(item);
      }
      // 大學 4 年
      for (let i = 0; i < 4; i += 1) {
        let item = {};
        item.label = `gradeLevel.grade${16 - i}`;
        item.param = curYear + i + offset;
        item.value = (curYear - (4 - i - offset)).toString();
        options.push(item);
      }
      // 高中以下 12 年級
      for (let i = 0; i < 12; i += 1) {
        let item = {};
        item.label = `gradeLevel.grade${12 - i}`;
        item.param = curYear + i + offset;
        item.value = (curYear + i + offset).toString();
        options.push(item);
      }
      return options;
    },
    createTeacherGraduateSelection() {
      const today = moment().format();
      const year = today.substring(0, 5);
      const month = today.substring(5, 7);
      let options = [];
      if (!month) return options;
      const offset = parseInt(month) < 7 ? 0 : 1;

      let curYear = parseInt(year);
      // 已畢業
      for (let i = 10; i > 0; i--) {
        let item = {};
        item.label = "gradeLevel.graduated";
        item.param = curYear - i + offset;
        item.value = (curYear - i + offset).toString();
        options.push(item);
      }
      // 大學 4 年
      for (let i = 0; i < 4; i += 1) {
        let item = {};
        item.label = `gradeLevel.grade${16 - i}`;
        item.param = curYear + i + offset;
        item.value = (curYear + (i + offset)).toString();
        options.push(item);
      }
      // 高中以下 12 年級
      for (let i = 0; i < 12; i += 1) {
        let item = {};
        item.label = `gradeLevel.grade${12 - i}`;
        item.param = curYear + i + offset;
        item.value = (curYear + 4 + i + offset).toString();
        options.push(item);
      }
      return options;
    }
  }
};
